export const upperCaseFirstLetterOfEachWord = (text: string): string => {
  if (!text) {
    return ''
  }

  const splitStr = text.toLowerCase().split(' ')

  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(' ')
}

export const getMultipleSuffix = (value: number, word: string) => {
  return value === 1 ? word : `${word}s`
}

export const stringFirstLetters = (str: string) => {
  return str
    .split(' ')
    .map((word) => word[0])
    .join('')
}
