import type { FC } from 'react'
import { memo, useEffect, useMemo } from 'react'
import { PATH_SEGMENT } from 'routes/pathSegments'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { generateRouter, PathUtils } from 'routes/routes.utils'
import { Staff } from './Staff/Staff'
import { Documents } from './Documents/Documents'
import { Shop } from './Shop/Shop'
import { TabPanel } from 'common/components/TabPanel/TabPanel'
import { bookingConfig } from './config'
import { useLazyFetchLeaseInfoQuery } from 'features/Home/Book/state/api/bookApi'
import { UPDATE_INFO_TIME_ZONE } from 'features/Home/Book/state/slice/bookSlice'
import { useAppDispatch } from 'common/hooks/redux'
import { BookingDetails } from './BookingDetails/BookingDetails'
import { Spinner } from 'common/components/Spinner'
import { PusherProvider } from 'app/providers/pusher/PusherProvider'
import { usePrevious } from 'react-use'
import { useAuth } from 'app/providers'

export const Booking: FC = memo(() => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { isStaff, isSuperStaff } = useAuth()

  const { bookingId, siteId } = useParams()

  const [fetchBooking, { data: booking, isFetching: isFetchingBooking }] =
    useLazyFetchLeaseInfoQuery()

  const statusCode = booking?.status_code
  const bookingType = booking?.lease_type_name
  const leaseTypeCode = booking?.lease_type_code
  const previousSiteId = usePrevious(siteId)

  const tabs = useMemo(() => {
    return bookingConfig.getTabs(bookingType, statusCode, isStaff, isSuperStaff)
  }, [booking, statusCode, isStaff, isSuperStaff])

  const routesConfig = useMemo(
    () => ({
      [PATH_SEGMENT.DETAILS]: (
        <BookingDetails booking={booking} isFetchingBooking={isFetchingBooking} />
      ),
      [`${PATH_SEGMENT.DETAILS}/:day`]: (
        <BookingDetails booking={booking} isFetchingBooking={isFetchingBooking} />
      ),
      [PATH_SEGMENT.SCHEDULE]: <Navigate to={PathUtils.getSchedule(siteId)} />,

      [PATH_SEGMENT.SHOP]: <Shop />,
      ...(!isStaff || isSuperStaff
        ? {
            [PATH_SEGMENT.STAFF]: <Staff />,
            [`${PATH_SEGMENT.DOCUMENTS}/*`]: <Documents />,
          }
        : {}),
      [PATH_SEGMENT.ROOT]: <Navigate to={PATH_SEGMENT.DETAILS} replace />,
    }),
    [booking, leaseTypeCode, isFetchingBooking, isStaff, isSuperStaff],
  )

  useEffect(() => {
    if (!bookingId || !siteId) {
      return
    }

    fetchBooking({ bookingId, siteId })
  }, [bookingId, siteId])

  useEffect(() => {
    if (!booking) {
      return
    }

    dispatch(UPDATE_INFO_TIME_ZONE(booking.time_zone))
  }, [booking])

  useEffect(() => {
    if (!!siteId && !!previousSiteId && siteId !== previousSiteId) {
      navigate(PATH_SEGMENT.ROOT)
    }
  }, [siteId, previousSiteId])

  if (isFetchingBooking || !booking || !bookingId || !siteId) {
    return (
      <div className='content-page-wrapper'>
        <Spinner size='large' isLoading />
      </div>
    )
  }

  return (
    <>
      <PusherProvider selectedSite={siteId} selectedLease={bookingId}>
        <>
          <TabPanel tabs={tabs} />
          {generateRouter(routesConfig)}
        </>
      </PusherProvider>
    </>
  )
})

export type BookingType = typeof Booking

Booking.displayName = 'Booking'
