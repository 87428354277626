import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { SMS_STATUSES } from 'features/Home/constants/infoConstants'
import { PATIENT_STATUSES } from 'features/Home/constants/infoConstants'
import { useInfoManager } from 'features/Home/hooks/useInfoManager'
import { useFetchScheduleDayQuery } from 'features/Schedule/api/schedule.api'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

dayjs.extend(customParseFormat)

export const useScheduleData = (date: string) => {
  const { siteId } = useParams()
  const { updateInfoDisabledIntervals } = useInfoManager(date)

  const { data: scheduleDay, isFetching: isFetchingDay } = useFetchScheduleDayQuery(
    { siteId, date },
    { skip: !date },
  )

  const rooms = useMemo(() => {
    if (!scheduleDay) return []
    return scheduleDay.rooms
  }, [JSON.stringify(scheduleDay?.rooms)])

  const schedules = useMemo(() => {
    if (!scheduleDay) return []
    return scheduleDay.items
  }, [JSON.stringify(scheduleDay?.items)])

  const patients = useMemo(() => {
    if (!scheduleDay) return []
    return scheduleDay.patients
  }, [JSON.stringify(scheduleDay?.patients)])

  const bookingIds = useMemo(() => {
    if (!scheduleDay) return []
    const bookingIdSet = new Set<string>()

    rooms.forEach((room) => {
      if (room.booking_id) {
        bookingIdSet.add(String(room.booking_id))
      }
    })
    return Array.from(bookingIdSet)
  }, [JSON.stringify(rooms)])

  const consultation_staffs = useMemo(() => {
    if (!scheduleDay) return []
    return scheduleDay.consultation_staffs
  }, [JSON.stringify(scheduleDay?.consultation_staffs)])

  const languages = useMemo(() => {
    if (!scheduleDay) return []
    return scheduleDay.languages
  }, [JSON.stringify(scheduleDay?.languages)])

  const timeInterval = useMemo(() => {
    if (!scheduleDay || scheduleDay?.time_interval?.min === null) return { min: null, max: null }
    return {
      min: dayjs(scheduleDay.time_interval.min, 'HH:mm:ss').format('HH:mm'),
      max: dayjs(scheduleDay.time_interval.max, 'HH:mm:ss').format('HH:mm'),
    }
  }, [JSON.stringify(scheduleDay?.time_interval)])

  const disabledIntervals = useMemo(() => {
    if (!scheduleDay) return []
    return updateInfoDisabledIntervals(scheduleDay.rooms, scheduleDay.time_interval)
  }, [JSON.stringify(scheduleDay?.rooms), JSON.stringify(scheduleDay?.time_interval)])

  const eventsToSendNotification = useMemo(() => {
    if (!scheduleDay) return []
    return scheduleDay.items
      .filter(
        (item) =>
          item.sms_notification === SMS_STATUSES.UNCONFIRMED &&
          item.status.code === PATIENT_STATUSES.UNCONFIRMED,
      )
      .map((item) => item.id)
  }, [JSON.stringify(scheduleDay?.items)])

  const roomStatuses = useMemo(() => {
    if (!scheduleDay) return []
    return scheduleDay.room_statuses
  }, [JSON.stringify(scheduleDay?.room_statuses)])

  const doctorBufferTime = useMemo(() => {
    if (!scheduleDay) return { before: null, after: null }
    return scheduleDay.doctor_buffer_time
  }, [JSON.stringify(scheduleDay?.doctor_buffer_time)])

  return {
    bookingIds,
    scheduleDay,
    rooms,
    schedules,
    patients,
    consultation_staffs,
    languages,
    timeInterval,
    disabledIntervals,
    isFetchingDay,
    eventsToSendNotification,
    roomStatuses,
    doctorBufferTime,
  }
}
