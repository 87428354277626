import React from 'react'

import { BUTTON_CONSTANTS, BUTTON_MODIFIER } from 'common/constants/buttonConstants'
import { Modal } from 'antd'
import { Button } from 'common/components/Button/Button'
import styles from './popup.module.scss'

const initialState = {
  isVisible: false,
  message: '',
  secondButtonText: '',
  mainButtonText: '',
  title: '',
}

type StateType = Partial<typeof initialState>

export default class Popup extends React.Component<any, StateType> {
  static popupRef: any
  sheetRef: React.RefObject<any>

  constructor(props: any) {
    super(props)
    Popup.popupRef = this
    this.sheetRef = React.createRef()

    this.state = { ...initialState }
  }

  static showInfo(details: StateType, callback?: () => void, secondCallback?: () => void) {
    Popup.popupRef.callback = callback
    Popup.popupRef.secondCallback = secondCallback

    Popup?.popupRef?.show({
      ...details,
    })
  }

  hide(call?: string) {
    this.setState({ ...initialState }, Popup?.popupRef[call])
  }

  show(props: StateType) {
    this.setState({ isVisible: true, ...props })
  }

  render() {
    const { isVisible, message, secondButtonText, mainButtonText, title } = this.state as StateType

    return (
      <Modal
        destroyOnClose
        className={styles.container}
        open={isVisible}
        cancelText={secondButtonText || BUTTON_CONSTANTS.CANCEL}
        title={title}
        onCancel={() => this.hide()}
        onClose={() => this.hide('secondCallback')}
        footer={
          <>
            <Button
              onClick={() => this.hide('secondCallback')}
              modifier={BUTTON_MODIFIER.SECONDARY}>
              {secondButtonText || BUTTON_CONSTANTS.CANCEL}
            </Button>
            <Button onClick={() => this.hide('callback')}>
              {mainButtonText || BUTTON_CONSTANTS.LOGIN}
            </Button>
          </>
        }>
        <span>{message}</span>
      </Modal>
    )
  }
}
