export enum SIDEBAR_CONSTANTS {
  HOME = 'Home',
  STAFF = 'Staff',
  PATIENTS = 'Patients',
  SUPPORT = 'Support',
  FAQ = 'F.a.q.',
  ADMIN = 'Admin',
  PROFILE = 'Profile',
  SCHEDULE = 'Schedule',
}
