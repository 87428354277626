import { createApi } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn } from '@reduxjs/toolkit/src/query/baseQueryTypes'
import type { EndpointBuilder } from '@reduxjs/toolkit/src/query/endpointDefinitions'

import { HTTP_METHODS } from 'common/constants/httpMethodsConstants'
import { baseQuery } from 'common/store/api/fetchBaseQuery'
import { REDUCERS } from 'common/store/reducerConstants'

import type {
  ScheduleAppointmentDto,
  ScheduleMonth,
  ScheduleDay,
  ScheduleDayResponse,
} from './schedule.types'
import type { IPatientRoomWithTimeSlots, IPatientRoom } from 'features/Home/interfaces/IInfoPatient'
import type { IPatientSchedule } from 'features/Home/interfaces/IInfoSchedule'
import { type PatientAppointmentType } from 'features/Schedule/pusher/type'
import {
  createPatientOptimistic,
  updatePatientOptimistic,
  deletePatientOptimistic,
  updatePatientStatusOptimistic,
  updateRoomStatusOptimistic,
} from 'features/Schedule/api/optimistics.updates'
import { PATIENT_STATUSES } from 'features/Home/constants/infoConstants'
import type { IPatientSelection } from 'features/Patients/interfaces/IPatient'

export enum SCHEDULE_TAGS {
  SCHEDULE = 'Schedule',
}

export const scheduleApi = createApi({
  reducerPath: REDUCERS.SCHEDULE,
  baseQuery: baseQuery(),
  tagTypes: Object.values(SCHEDULE_TAGS),
  endpoints: (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
    fetchScheduleMonth: build.query<
      ScheduleMonth,
      { siteId: string; startDate: string; endDate: string }
    >({
      query: ({ siteId, startDate, endDate }) => ({
        url: `/schedule/month/${startDate}/${endDate}`,
        headers: { 'Database-Connection': siteId },
      }),
      providesTags: [SCHEDULE_TAGS.SCHEDULE],
    }),

    fetchScheduleDay: build.query<ScheduleDay, { siteId: string; date: string }>({
      query: ({ siteId, date }) => ({
        url: `/schedule/day/${date}`,
        headers: { 'Database-Connection': siteId },
      }),
      transformResponse: (response: ScheduleDayResponse): ScheduleDay => {
        const rooms = getAdjustedRooms(response.rooms)
        return {
          ...response,
          rooms,
          items: response.items.map((item) => ({
            ...item,
            room: rooms.find((room) => {
              return room.value === item.room
            }),
          })) as IPatientSchedule[],
        }
      },
      providesTags: [SCHEDULE_TAGS.SCHEDULE],
    }),

    createScheduleAppointment: build.mutation<
      PatientAppointmentType,
      { siteId: string; date: string; data: ScheduleAppointmentDto }
    >({
      query: ({ siteId, date, data }) => ({
        url: `/schedule/day/${date}/appointment`,
        method: HTTP_METHODS.POST,
        headers: { 'Database-Connection': siteId },
        data,
      }),
      async onQueryStarted({ siteId, date }, { dispatch, queryFulfilled }) {
        try {
          const { data: responseData } = await queryFulfilled
          createPatientOptimistic(dispatch, siteId, date, responseData)
        } catch {
          console.log('Something went wrong with createScheduleAppointment')
        }
      },
    }),

    updateScheduleAppointment: build.mutation<
      PatientAppointmentType,
      { siteId: string; date: string; appointmentId: number; data: ScheduleAppointmentDto }
    >({
      query: ({ siteId, date, appointmentId, data }) => ({
        url: `/schedule/day/${date}/appointment/${appointmentId}`,
        method: HTTP_METHODS.PUT,
        headers: { 'Database-Connection': siteId },
        data,
      }),
      async onQueryStarted({ siteId, date }, { dispatch, queryFulfilled }) {
        try {
          const { data: responseData } = await queryFulfilled
          updatePatientOptimistic(dispatch, siteId, date, responseData)
        } catch {
          console.log('Something went wrong with updateScheduleAppointment')
        }
      },
    }),

    notifyMultiplePatient: build.mutation<void, { siteId: string; appointmentIds: number[] }>({
      query: ({ siteId, appointmentIds }) => ({
        url: `/schedule/send-notification`,
        method: HTTP_METHODS.POST,
        headers: { 'Database-Connection': siteId },
        data: { appointment_ids: appointmentIds },
      }),
    }),

    deleteScheduleAppointment: build.mutation<
      void,
      { siteId: string; date: string; appointmentId: number }
    >({
      query: ({ siteId, date, appointmentId }) => ({
        url: `/schedule/day/${date}/appointment/${appointmentId}`,
        method: HTTP_METHODS.DELETE,
        headers: { 'Database-Connection': siteId },
      }),
      async onQueryStarted({ siteId, date, appointmentId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          deletePatientOptimistic(dispatch, siteId, date, appointmentId)
        } catch {
          console.log('Something went wrong with deleteScheduleAppointment')
        }
      },
    }),

    checkInPatient: build.mutation<
      { temperature: number },
      { siteId: string; date: string; appointmentId: number; access_code: number | string }
    >({
      query: ({ siteId, appointmentId, access_code }) => ({
        url: `/schedule/check-in-patient/${appointmentId}`,
        headers: { 'Database-Connection': siteId },
        method: HTTP_METHODS.POST,
        data: { access_code },
      }),
      async onQueryStarted({ siteId, date, appointmentId }, { dispatch, queryFulfilled }) {
        try {
          const { data: responseData } = await queryFulfilled
          updatePatientStatusOptimistic(
            dispatch,
            siteId,
            date,
            appointmentId,
            PATIENT_STATUSES.CHECKED_IN_ON_SITE,
            responseData.temperature,
          )
        } catch {
          console.log('Something went wrong with checkInPatient')
        }
      },
    }),

    notifyPatient: build.mutation<void, { siteId: string; appointmentId: number }>({
      query: ({ siteId, appointmentId }) => ({
        url: `/schedule/notify-patient/${appointmentId}`,
        method: HTTP_METHODS.POST,
        headers: { 'Database-Connection': siteId },
      }),
    }),

    updateRoomStatus: build.mutation<
      {
        room_status: {
          code: number
          name: string
        }
        patients: IPatientSelection[]
      },
      {
        siteId: string
        date: string
        roomId: number
        data: {
          status_type_code: number
          lease_id: string
          force_update?: number
        }
      }
    >({
      query: ({ siteId, roomId, data }) => ({
        data,
        url: `/room/${roomId}/status`,
        method: HTTP_METHODS.PUT,
        headers: { 'Database-Connection': siteId },
      }),
      async onQueryStarted({ siteId, date, roomId }, { dispatch, queryFulfilled }) {
        try {
          const { data: responseData } = await queryFulfilled
          updateRoomStatusOptimistic(dispatch, siteId, date, roomId, responseData.room_status.code)
        } catch {
          console.log('Something went wrong with updateRoomStatus')
        }
      },
    }),
    updateSelectPatients: build.mutation<
      { data: { status: boolean; message: string } },
      { siteId: string; roomId: number; data: { appointment_ids: number[] } }
    >({
      query: ({ siteId, roomId, data }) => ({
        data,
        url: `/room/${roomId}/appointments`,
        method: HTTP_METHODS.POST,
        headers: { 'Database-Connection': siteId },
      }),
    }),
  }),
})

export const {
  useFetchScheduleMonthQuery,
  useFetchScheduleDayQuery,
  useCreateScheduleAppointmentMutation,
  useUpdateScheduleAppointmentMutation,
  useNotifyPatientMutation,
  useNotifyMultiplePatientMutation,
  useDeleteScheduleAppointmentMutation,
  useCheckInPatientMutation,
  useUpdateRoomStatusMutation,
  useUpdateSelectPatientsMutation,
} = scheduleApi

const getAdjustedRooms = (rooms: IPatientRoomWithTimeSlots[]): IPatientRoom[] => {
  return rooms.map((room) => ({
    ...room,
    slots: room.time_slots,
    value: String(room.code),
    label: room.name,
    is_waiting_room: !!room.is_waiting_room,
  }))
}
