import { PATH_SEGMENT } from 'routes/pathSegments'

import staffIcon from './assets/staff.svg'
import { SIDEBAR_CONSTANTS } from './sidebar.constants'
import type { ISidebarTab } from './sidebar.types'

const guestBannedRoutes = [
  PATH_SEGMENT.STAFF,
  PATH_SEGMENT.PATIENTS,
  PATH_SEGMENT.SUPPORT,
  PATH_SEGMENT.SCHEDULE,
]
const unconfirmedDoctorBannedRoutes = [PATH_SEGMENT.PATIENTS]
const staffBannedRoutes = [PATH_SEGMENT.STAFF]

const config = {
  getItems: ({
    isGuest,
    isUnconfirmedDoctor,
    isSuperDoctor,
    isStaff,
  }: {
    isGuest: boolean
    isUnconfirmedDoctor: boolean
    isSuperDoctor: boolean
    isStaff: boolean
  }): ISidebarTab[] => {
    const allItems: ISidebarTab[] = [
      {
        route: PATH_SEGMENT.HOME,
        title: SIDEBAR_CONSTANTS.HOME,
        icon: 'ph:house-line-light',
      },
      {
        route: PATH_SEGMENT.PROFILE,
        title: SIDEBAR_CONSTANTS.PROFILE,
        icon: 'ph:user',
      },
      {
        route: PATH_SEGMENT.SCHEDULE,
        title: SIDEBAR_CONSTANTS.SCHEDULE,
        icon: 'mingcute:schedule-line',
      },
      {
        route: PATH_SEGMENT.STAFF,
        title: SIDEBAR_CONSTANTS.STAFF,
        icon: staffIcon,
      },
      {
        route: PATH_SEGMENT.PATIENTS,
        title: SIDEBAR_CONSTANTS.PATIENTS,
        icon: 'ph:users-three-light',
      },
      {
        route: PATH_SEGMENT.SUPPORT,
        title: SIDEBAR_CONSTANTS.SUPPORT,
        icon: 'ph:chats-circle',
      },
      {
        route: PATH_SEGMENT.FAQ,
        title: SIDEBAR_CONSTANTS.FAQ,
        icon: 'ph:rows',
      },
      {
        route: PATH_SEGMENT.ADMIN,
        title: SIDEBAR_CONSTANTS.ADMIN,
        icon: 'ph:shield-check-light',
      },
    ]

    return allItems.filter((item) => {
      if (isGuest && guestBannedRoutes.includes(item.route)) {
        return false
      }

      if (isUnconfirmedDoctor && unconfirmedDoctorBannedRoutes.includes(item.route)) {
        return false
      }

      if (isStaff && staffBannedRoutes.includes(item.route)) {
        return false
      }

      return !(!isSuperDoctor && item.route === PATH_SEGMENT.ADMIN)
    })
  },
}

export type SidebarConfigType = typeof config

export const sidebarConfig: SidebarConfigType = Object.freeze(config)
