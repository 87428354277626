import { BOOKING_STATUS_CODE } from 'common/constants/bookingConstants'
import { PATH_SEGMENT } from 'routes/pathSegments'

const config = (statusCode: BOOKING_STATUS_CODE) => ({
  tabs: [
    {
      path: PATH_SEGMENT.DETAILS,
      title: 'Package Details',
    },
    {
      path: PATH_SEGMENT.SCHEDULE,
      title: 'Schedule',
    },

    {
      path: PATH_SEGMENT.STAFF,
      title: 'Staff',
    },
    {
      path: PATH_SEGMENT.NOTIFICATIONS,
      title: 'Notifications',
    },
    {
      path: PATH_SEGMENT.DOCUMENTS,
      title: 'Documents',
    },
    {
      path: PATH_SEGMENT.SHOP,
      title: 'Shop',
      disabled: statusCode === BOOKING_STATUS_CODE.FINISHED,
    },
  ],
})

export type PromotionConfigType = typeof config

export const promotionConfig: PromotionConfigType = Object.freeze(config)
