import { type AppDispatch } from 'common/hooks/redux'
import { scheduleApi } from './schedule.api'
import { type PatientAppointmentType } from 'features/Schedule/pusher/type'
import _ from 'lodash'
import { type IPatientStatus } from 'features/Home/interfaces/IInfoSchedule'

export const updateRoomStatusOptimistic = (
  dispatch: AppDispatch,
  siteId: string,
  date: string,
  room_id: number,
  room_status_code: number,
) => {
  dispatch(
    scheduleApi.util.updateQueryData('fetchScheduleDay', { siteId, date }, (draft) => {
      if (!draft || !draft.rooms) return

      const roomIndex = draft.rooms.findIndex((room) => room.id === room_id)
      if (roomIndex !== -1) {
        const status = draft.room_statuses.find((status) => status.code === room_status_code)
        draft.rooms[roomIndex] = {
          ...draft.rooms[roomIndex],
          status,
        }
      }
    }) as any,
  )
}

export const updatePatientOptimistic = (
  dispatch: AppDispatch,
  siteId: string,
  date: string,
  patientData: PatientAppointmentType,
) => {
  dispatch(
    scheduleApi.util.updateQueryData('fetchScheduleDay', { siteId, date }, (draft) => {
      if (!draft || !draft.items) return
      const room = draft.rooms.find((room) => room.value === patientData.room)
      const originalIndex = draft.items.findIndex((item) => item.id === patientData.id)

      if (originalIndex !== -1) {
        const newObj = {
          ...patientData,
          room,
        }
        const isDeepEqual = _.isEqual(draft.items[originalIndex], newObj)

        if (!isDeepEqual) {
          draft.items.splice(originalIndex, 1, newObj)
        }
      }
    }) as any,
  )
}

export const deletePatientOptimistic = (
  dispatch: AppDispatch,
  siteId: string,
  date: string,
  appointment_id: number,
) => {
  dispatch(
    scheduleApi.util.updateQueryData('fetchScheduleDay', { siteId, date }, (draft) => {
      if (!draft || !draft.items) return
      const originalIndex = draft.items.findIndex((item) => item.id === appointment_id)
      if (originalIndex !== -1) {
        draft.items.splice(originalIndex, 1)
      }
    }) as any,
  )
}
export const createPatientOptimistic = (
  dispatch: AppDispatch,
  siteId: string,
  date: string,
  patientData: PatientAppointmentType,
) => {
  dispatch(
    scheduleApi.util.updateQueryData('fetchScheduleDay', { siteId, date }, (draft) => {
      if (!draft || !draft.items) return
      const hasPatient = draft.items.find((item) => item.id === patientData.id)
      if (hasPatient) return
      const room = draft.rooms.find((room) => room.value === patientData.room)
      draft.items.push({
        ...patientData,
        room,
      })
    }) as any,
  )
}

export const updatePatientStatusOptimistic = (
  dispatch: AppDispatch,
  siteId: string,
  date: string,
  appointment_id: number,
  status_code: number,
  temperature: number,
) => {
  dispatch(
    scheduleApi.util.updateQueryData('fetchScheduleDay', { siteId, date }, (draft) => {
      if (!draft || !draft.items) return

      const status = draft.patient_statuses.find((status) => status.code === status_code)!
      const originalIndex = draft.items.findIndex((item) => item.id === appointment_id)
      if (originalIndex !== -1) {
        draft.items[originalIndex] = {
          ...draft.items[originalIndex],
          status: status as IPatientStatus,
          temperature,
        }
      }
    }) as any,
  )
}

export const updatePatientLocationOptimistic = (
  dispatch: AppDispatch,
  siteId: string,
  date: string,
  appointment_id: number,
  room_id: number,
) => {
  dispatch(
    scheduleApi.util.updateQueryData('fetchScheduleDay', { siteId, date }, (draft) => {
      if (!draft || !draft.items) return

      const room = draft.rooms.find((room) => room.id === room_id)
      const originalIndex = draft.items.findIndex((item) => item.id === appointment_id)
      if (originalIndex !== -1) {
        draft.items[originalIndex] = {
          ...draft.items[originalIndex],
          room,
        }
      }
    }) as any,
  )
}
