import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useAppDispatch } from 'common/hooks/redux'
import { debugPusher, useMultipleChannels } from 'features/Schedule/pusher/MultiplePusherProvider'

import { PUSHER_EVENTS } from 'features/Home/constants/infoConstants'
import type {
  RoomStatusUpdateEvent,
  PatientUpdateEvent,
  PatientDeleteEvent,
  PatientStatusUpdateEvent,
  MassUpdateEvent,
  PatientLocationUpdateEvent,
} from 'features/Schedule/pusher/type'
import {
  updateRoomStatusOptimistic,
  updatePatientOptimistic,
  deletePatientOptimistic,
  createPatientOptimistic,
  updatePatientStatusOptimistic,
  updatePatientLocationOptimistic,
} from 'features/Schedule/api/optimistics.updates'
import Popup from 'common/components/Popup'
import { useNavigate } from 'react-router-dom'

export const useSchedulePusher = (date: string) => {
  const { channels, bookingIds } = useMultipleChannels()
  const { siteId } = useParams()
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const massUpdate = (event: MassUpdateEvent) => {
    if (debugPusher) console.log('MASS_UPDATE event received:', event)
    const { day, shouldUpdate } = event
    if (day === date && shouldUpdate) {
      Popup.showInfo(
        {
          title: 'Schedule updated',
          message: 'You have a mass update. Would you like to refresh the page?',
          mainButtonText: 'Refresh',
          secondButtonText: 'No',
        },
        () => navigate(0),
      )
    }
  }

  const patientUpdate = (event: PatientUpdateEvent) => {
    if (debugPusher) console.log('PATIENT_UPDATED event received:', event)
    const { data, shouldUpdate } = event
    if (shouldUpdate && data) {
      updatePatientOptimistic(dispatch, siteId, date, data)
    }
  }

  const patientCreate = (event: PatientUpdateEvent) => {
    if (debugPusher) console.log('PATIENT_CREATE event received:', event)
    const { data, shouldUpdate } = event
    if (shouldUpdate && data) {
      createPatientOptimistic(dispatch, siteId, date, data)
    }
  }

  const patientDelete = (event: PatientDeleteEvent) => {
    if (debugPusher) console.log('PATIENT_DELETE event received:', event)
    const { appointment_id, shouldUpdate } = event
    if (shouldUpdate) {
      deletePatientOptimistic(dispatch, siteId, date, appointment_id)
    }
  }

  const patientLocationUpdate = (event: PatientLocationUpdateEvent) => {
    if (debugPusher) console.log('PATIENT_LOCATION_UPDATE event received:', event)
    const { appointment_id, room_id, shouldUpdate } = event
    if (shouldUpdate) {
      updatePatientLocationOptimistic(dispatch, siteId, date, appointment_id, room_id)
    }
  }

  const patientStatusUpdate = (event: PatientStatusUpdateEvent) => {
    if (debugPusher) console.log('PATIENT_STATUS_UPDATE event received:', event)
    const { appointment_id, shouldUpdate, status_code, temperature } = event
    if (shouldUpdate) {
      updatePatientStatusOptimistic(
        dispatch,
        siteId,
        date,
        appointment_id,
        status_code,
        temperature,
      )
    }
  }

  const roomStatusUpdate = (event: RoomStatusUpdateEvent) => {
    if (debugPusher) console.log('ROOM_STATUS_UPDATE event received:', event)
    const { room_id, room_status_code, shouldUpdate } = event
    if (shouldUpdate) {
      updateRoomStatusOptimistic(dispatch, siteId, date, room_id, room_status_code)
    }
  }

  useEffect(() => {
    if (!channels.size || !bookingIds.length) {
      if (debugPusher)
        console.log('No channels or bookingIds available for event binding', {
          channelsSize: channels.size,
          bookingIdsLength: bookingIds.length,
        })
      return
    }

    if (debugPusher) console.log('Binding events to channels, count:', channels.size)

    channels.forEach((channel, channelName) => {
      if (debugPusher) console.log(`Binding events to channel: ${channelName}`)

      channel.bind(PUSHER_EVENTS.MASS_UPDATE, massUpdate)
      channel.bind(PUSHER_EVENTS.PATIENT_UPDATED, patientUpdate)
      channel.bind(PUSHER_EVENTS.PATIENT_CREATE, patientCreate)
      channel.bind(PUSHER_EVENTS.PATIENT_DELETE, patientDelete)
      channel.bind(PUSHER_EVENTS.PATIENT_LOCATION_UPDATE, patientLocationUpdate)
      channel.bind(PUSHER_EVENTS.PATIENT_STATUS_UPDATE, patientStatusUpdate)
      channel.bind(PUSHER_EVENTS.ROOM_STATUS_UPDATE, roomStatusUpdate)
    })

    return () => {
      if (debugPusher) console.log('Unbinding events from channels')

      channels.forEach((channel, channelName) => {
        if (debugPusher) console.log(`Unbinding events from channel: ${channelName}`)

        channel.unbind(PUSHER_EVENTS.MASS_UPDATE, massUpdate)
        channel.unbind(PUSHER_EVENTS.PATIENT_UPDATED, patientUpdate)
        channel.unbind(PUSHER_EVENTS.PATIENT_CREATE, patientCreate)
        channel.unbind(PUSHER_EVENTS.PATIENT_DELETE, patientDelete)
        channel.unbind(PUSHER_EVENTS.PATIENT_LOCATION_UPDATE, patientLocationUpdate)
        channel.unbind(PUSHER_EVENTS.PATIENT_STATUS_UPDATE, patientStatusUpdate)
        channel.unbind(PUSHER_EVENTS.ROOM_STATUS_UPDATE, roomStatusUpdate)
      })
    }
  }, [channels, JSON.stringify(bookingIds)])
}
