import type { FC } from 'react'
import { memo, useMemo } from 'react'
import { generateRouter } from 'routes/routes.utils'
import { PATH_SEGMENT } from 'routes/pathSegments'
import { Navigate, useParams } from 'react-router-dom'
import SchedulePage from './pages/Schedule'
import { MultiplePusherProvider } from './pusher/MultiplePusherProvider'

export const Schedule: FC = memo(() => {
  const { siteId } = useParams()

  const routesConfig = useMemo(
    () => ({
      [PATH_SEGMENT.ROOT]: <SchedulePage />,
      [`${PATH_SEGMENT.ROOT}/:day`]: <SchedulePage />,
      [PATH_SEGMENT.OTHER]: <Navigate to={PATH_SEGMENT.ROOT} replace />,
    }),
    [],
  )

  return (
    <>
      <MultiplePusherProvider selectedSite={siteId}>
        {generateRouter(routesConfig)}
      </MultiplePusherProvider>
    </>
  )
})

export type ScheduleType = typeof Schedule

Schedule.displayName = 'Schedule'
