import styles from './horizontal-calendar.module.scss'
import { useEffect, useState, useMemo } from 'react'

import { Popover, Skeleton, Tooltip, Spin } from 'antd'
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar-date.svg'
import { ReactComponent as PrevIcon } from 'assets/icons/left.svg'
import { ReactComponent as NextIcon } from 'assets/icons/right.svg'
import { generateDates } from './calendar.util'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { Calendar } from 'common/components/Calendar/Calendar'
import { DateService } from 'common/services/dateService'

const DATE_FORMAT = 'YYYY-MM-DD'

const { preserveDateInTimezone } = DateService

type Props = {
  isLoading?: boolean
  isFetching?: boolean
  defaultActiveDate: string | null
  timeZone?: string
  availableDays?: string[]
  allowDisabled?: boolean
  allowPastDate?: boolean
  forceEnableFutureDate?: boolean
  forceEnablePastDate?: boolean
  minDate?: Dayjs
  maxDate?: Dayjs
  onDateChange?: (date: string) => void
  handleClickDisabledDate?: (date: string) => void
  onChangeMonth?: (date: Dayjs) => void
}

const HorizontalCalendar = ({
  isLoading,
  isFetching = false,
  allowDisabled = false,
  allowPastDate = false,
  defaultActiveDate = dayjs().format(DATE_FORMAT),
  timeZone,
  availableDays = [],
  minDate,
  maxDate,
  forceEnableFutureDate = false,
  forceEnablePastDate = false,
  onDateChange = () => {},
  handleClickDisabledDate = () => {},
  onChangeMonth = () => {},
}: Props) => {
  const [activeDate, setActiveDate] = useState<string>(defaultActiveDate)
  const [startInterval, setStartInterval] = useState<string>(defaultActiveDate)
  const dates = generateDates(startInterval, timeZone)
  const currentDate = dayjs(activeDate)

  const popoverCalendarActiveDays = useMemo(() => {
    const days = availableDays.reduce((acc, day) => {
      acc[day] = {
        active: 1,
      }
      return acc
    }, {})
    return days
  }, [availableDays])

  useEffect(() => {
    if (defaultActiveDate) {
      setActiveDate(defaultActiveDate)
      setStartInterval(defaultActiveDate)
    }
  }, [defaultActiveDate])

  const startOfCurrentWeek = dayjs(startInterval).startOf('week')

  const isPrevWeekDisabled = !forceEnablePastDate && startOfCurrentWeek.isBefore(availableDays?.[0])
  const isNextWeekDisabled =
    !forceEnableFutureDate && startOfCurrentWeek.add(7, 'days').isAfter(availableDays?.at(-1))

  const handleNextClick = () => {
    const nextDate = dayjs(startInterval).add(7, 'days').format(DATE_FORMAT)
    setStartInterval(nextDate)
    const currentMonth = dayjs(startInterval).format('MMM')
    const nextMonth = dayjs(nextDate).format('MMM')
    if (currentMonth !== nextMonth) onChangeMonth(dayjs(nextDate).startOf('month'))
  }

  const handlePrevClick = () => {
    const prevDate = dayjs(startInterval).subtract(7, 'days').format(DATE_FORMAT)
    setStartInterval(prevDate)
    const currentMonth = dayjs(startInterval).format('MMM')
    const prevMonth = dayjs(prevDate).format('MMM')
    if (currentMonth !== prevMonth) onChangeMonth(dayjs(prevDate).startOf('month'))
  }

  const onChangeDate = (date: Dayjs) => {
    const newData = date.format(DATE_FORMAT)
    setActiveDate(newData)
    onDateChange(newData)
  }

  const checkDisabledDate = (date: Dayjs) => {
    if (allowDisabled) return false
    return !availableDays.includes(date.format(DATE_FORMAT))
  }

  const isDisabledPastDate = (date: Dayjs) => {
    if (!allowPastDate) return date.isBefore(minDate, 'day') && checkDisabledDate(date)
    return false
  }

  const handleDateClick = (date: Dayjs) => {
    if (!checkDisabledDate(date)) return onChangeDate(date)
    handleClickDisabledDate(date.format(DATE_FORMAT))
  }

  const getIsCurrentDate = (candidateDate: Dayjs) => {
    if (!timeZone) return dayjs().isSame(candidateDate, 'day')
    return dayjs().tz(timeZone, true).isSame(candidateDate, 'day')
  }

  const calendarPopover = (
    <Calendar
      handleChangeDate={(d) => handleDateClick(dayjs(d, DATE_FORMAT))}
      triggerInitialEffect={false}
      days={popoverCalendarActiveDays}
      currentDay={defaultActiveDate}
      selectedDays={availableDays}
      minDate={minDate}
      maxDate={maxDate}
      isStrictDisable
    />
  )
  if (isLoading) return <Skeleton.Node className={styles.skeleton} active />
  return (
    <Spin spinning={isFetching} className={styles.container}>
      <div className={styles.content}>
        <div className={styles.monthContainer}>
          <div className={styles.monthLabel}>{startOfCurrentWeek.format('MMM')}</div>
          <div className={styles.dateLabel}>{startOfCurrentWeek.format('YYYY')}</div>
        </div>

        <Tooltip title='Previous week'>
          <button
            disabled={isPrevWeekDisabled}
            className={styles.actionButton}
            onClick={handlePrevClick}>
            <PrevIcon />
          </button>
        </Tooltip>

        {dates.map((_date) => {
          const date = preserveDateInTimezone(_date, timeZone)
          const dayNumber = date.format('D')
          const dayText = date.format('ddd')
          const isActive = activeDate === date.format(DATE_FORMAT)
          const isCurrent = getIsCurrentDate(date)
          const isDisabled = checkDisabledDate(date)

          return (
            <button
              disabled={isDisabledPastDate(date)}
              key={dayText}
              className={styles.dateCell}
              data-disabled={isDisabled}
              data-active={isActive}
              data-current={isCurrent}
              onClick={() => handleDateClick(date)}>
              <p className={styles.dayNumber}>{dayNumber}</p>
              <p className={styles.dayText}>{dayText}</p>
            </button>
          )
        })}

        <Popover
          content={calendarPopover}
          rootClassName={styles.popoverContainer}
          destroyTooltipOnHide
          placement='bottomRight'
          trigger='click'>
          <button className={styles.calendarIcon}>
            <CalendarIcon />
          </button>
        </Popover>

        <Tooltip title='Next week'>
          <button
            disabled={isNextWeekDisabled}
            className={styles.actionButton}
            onClick={handleNextClick}>
            <NextIcon />
          </button>
        </Tooltip>
      </div>
    </Spin>
  )
}

export default HorizontalCalendar
