import './patientSelection.scss'

import { useParams } from 'react-router-dom'

import { Checkbox, Spin } from 'antd'
import { useFormik } from 'formik'

import { useNotification } from 'app/providers'

import { Button } from 'common/components/Button/Button'
import { Modal } from 'common/components/Modal'
import { ALERT_CONSTANTS } from 'common/constants/alertConstants'
import { BUTTON_SEVERITY } from 'common/constants/buttonConstants'
import { RESPONSE_PROPERTY_CONSTANTS } from 'common/constants/reponsePropertyConstants'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import type { ITriggerRequest } from 'common/interfaces/IRequestResponse'

import { ROOM_STATUS } from 'features/Home/constants/infoConstants'
import { useLazyFetchPatientActivityQuery } from 'features/Home/Book/state/api/bookApi'
import {
  useUpdateRoomStatusMutation,
  useUpdateSelectPatientsMutation,
} from 'features/Schedule/api/schedule.api'
import type { IInfoSlice } from 'features/Home/Book/state/interface/IInfoSlice'
import type { IPatientSelection } from 'features/Patients/interfaces/IPatient'
import type { FC } from 'react'
import { memo } from 'react'
import { UPDATE_INFO_PATIENTS_SELECTION } from 'features/Home/Book/state/slice/bookSlice'

const MaxPatientsSelection = 2

export const PatientSelection: FC = memo(() => {
  const { siteId, day } = useParams()
  const { setNotification } = useNotification()
  const dispatch = useAppDispatch()
  const leaseConnectionDetails = {
    siteId: siteId || '',
  }

  const [updateSelectPatients, { isLoading: isUpdatingRoomPatients }] =
    useUpdateSelectPatientsMutation()
  const [updateScheduleRoomStatus, { isLoading: isRevertingStatus }] = useUpdateRoomStatusMutation()
  const [fetchActivity] = useLazyFetchPatientActivityQuery()

  const { patientsSelection }: { patientsSelection: IInfoSlice['patientsSelection'] } =
    useAppSelector((state) => state.bookReducer)

  const { values, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues: {
      appointment_ids: [],
    },
    onSubmit,
  })

  const hasSelectedPatients = values.appointment_ids.length > 0
  const hasReachedMaximum = values.appointment_ids.length >= MaxPatientsSelection

  const updateAppointments = (id: number) => {
    if (values.appointment_ids.includes(id)) {
      const appts = [...values.appointment_ids]
      const newAppts = appts.filter((appt) => appt !== id)
      setFieldValue('appointment_ids', newAppts)
    } else {
      setFieldValue('appointment_ids', [...values.appointment_ids, id])
    }
  }

  const onResetForm = () => {
    dispatch(
      UPDATE_INFO_PATIENTS_SELECTION({
        roomId: null,
        patients: [],
        isPatientSelectionVisible: false,
      }),
    )
    resetForm()
  }

  const handleClose = async () => {
    if (isUpdatingRoomPatients || isRevertingStatus) return
    await updateScheduleRoomStatus({
      siteId: leaseConnectionDetails.siteId,
      date: day,
      roomId: patientsSelection.room?.id,
      data: {
        status_type_code: ROOM_STATUS.FREE,
        lease_id: patientsSelection.room?.booking_id,
        force_update: 1,
      },
    })
    onResetForm()
  }

  async function onSubmit() {
    const { appointment_ids } = values
    let ids = appointment_ids
    if (appointment_ids.length === 0) {
      ids = [patientsSelection.patients[0].id]
    }
    const response = (await updateSelectPatients({
      ...leaseConnectionDetails,
      roomId: patientsSelection.room?.id,
      data: {
        appointment_ids: ids,
      },
    })) as any

    if (response.hasOwnProperty(RESPONSE_PROPERTY_CONSTANTS.SUCCESS)) {
      if (!response.data.status) {
        setNotification({
          title: 'Update room status error',
          description: response.data.message,
          type: ALERT_CONSTANTS.ERROR,
        })

        await updateScheduleRoomStatus({
          siteId: leaseConnectionDetails.siteId,
          date: day,
          roomId: patientsSelection.room?.id,
          data: {
            status_type_code: ROOM_STATUS.BUSY,
            lease_id: patientsSelection.room?.booking_id,
            force_update: 1,
          },
        })
      }
      onResetForm()
      fetchActivity({ ...leaseConnectionDetails, date: day })
    } else {
      setNotification({
        title: 'Update room status error',
        description: response.error.data,
        type: ALERT_CONSTANTS.ERROR,
      })
    }
  }

  return (
    <Modal
      title='test'
      open={patientsSelection.isPatientSelectionVisible}
      onCancel={handleClose}
      destroyOnClose>
      <Spin spinning={isUpdatingRoomPatients || isRevertingStatus}>
        <form className='patient-selection-container' onSubmit={handleSubmit}>
          <Button
            htmlType='submit'
            id='action-button'
            data-selected={hasSelectedPatients}
            severity={BUTTON_SEVERITY.SUCCESS_FILLED}>
            {!hasSelectedPatients ? 'ORIGINAL APPOINTMENT ORDER' : 'SUBMIT'}
          </Button>
          <p className='info-select'>or select up to 2 patients:</p>
          {patientsSelection.patients.map((patient: IPatientSelection) => {
            const isChecked = values.appointment_ids.includes(patient.id)
            return (
              <div className='item' key={patient.id}>
                <Checkbox
                  onChange={() => updateAppointments(patient.id)}
                  checked={isChecked}
                  disabled={hasReachedMaximum && !isChecked}>
                  {patient.patient_name}
                </Checkbox>
                <p className='interval'>{patient.appointment_time_interval}</p>
              </div>
            )
          })}
        </form>
      </Spin>
    </Modal>
  )
})
