import './scheduleCalendar.scss'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Day,
  DragAndDrop,
  Inject,
  Resize,
  ResourceDirective,
  ResourcesDirective,
  ScheduleComponent,
  ViewDirective,
  ViewsDirective,
} from '@syncfusion/ej2-react-schedule'

import { extend } from '@syncfusion/ej2-base'
import type { ScheduleCalendarProps } from './scheduleCalendar.types'
import type { ObjectType } from 'common/interfaces/object'
import { ZoomControls } from 'features/Home/Book/Booking/Schedule/PatientSchedule/Calendar/ZoomControls/ZoomControls'
import { InfoService } from 'features/Home/services/infoService'
import CalendarEvent from './CalendarEvent'
// import EventTooltip from './EventTooltip'
import { useScheduleCalendar } from './useScheduleCalendar'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { INFO_CONSTANTS, MIN_PRE_POPULATE_TIME } from 'features/Home/constants/infoConstants'
import { DateService } from 'common/services/dateService'
import { Button } from 'common/components/Button/Button'
import { BUTTON_CONSTANTS, BUTTON_MODIFIER } from 'common/constants/buttonConstants'
import sendMessageIcon from 'assets/icons/send-message.svg'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { Show } from 'common/components/Show/Show'
import { useToggle } from 'react-use'
import { Warning } from 'features/Home/Book/Booking/Schedule/PatientSchedule/Calendar/Warning/Warning'
import { SendSMSConfirmation } from 'features/Home/Book/Booking/Schedule/PatientSchedule/Calendar/SendSMSConfirmation/SendSMSConfirmation'
import { useInfoManager } from 'features/Home/hooks/useInfoManager'
import { Spinner } from 'common/components/Spinner'

export const ScheduleCalendar = memo(
  ({
    createCustomAppointment,
    setEditedPatient,
    handleUpdateEvents,
    prepopulateTimeOnClick = MIN_PRE_POPULATE_TIME,
    resources = [],
    dayInterval,
    disabledIntervals,
    timeZone,
    rooms,
    isUpdating,
    schedules,
    isSendingNotifications,
    eventsToSendNotification,
  }: ScheduleCalendarProps) => {
    const { day } = useParams()
    const [interval, setInterval] = useState(15)
    const [showBusyNotification, setShowBusyNotification] = useToggle(false)
    const [isSendSmsConfirmVisible, setIsSendSmsConfirmVisible] = useToggle(false)
    const [isLoading, setIsLoading] = useState(false)

    const scheduleObj = useRef<ScheduleComponent>()
    const scheduleContainerRef = useRef<HTMLDivElement>(null)

    const formattedDate = useMemo(() => {
      if (!timeZone || !day) {
        return null
      }

      return dayjs(day).tz(timeZone, true).format('YYYY-MM-DD')
    }, [timeZone, day])

    const { sendInfoPatientsNotification } = useInfoManager(formattedDate)

    const isInPast = useMemo(() => {
      if (!formattedDate) {
        return
      }

      return DateService.isPast(new Date(`${formattedDate} 23:59`), timeZone)
    }, [formattedDate, timeZone])

    const {
      events,
      awaitConfirmationEvent,
      handleScheduleChange,
      setAwaitConfirmationEvent,
      proceedToUpdateEvents,
      handleSelect,
    } = useScheduleCalendar({
      schedules,
      dayInterval,
      disabledIntervals,
      formattedDate,
      timeZone,
      rooms,
      isUpdating,
      scheduleObj,
      isInPast,
      prepopulateTimeOnClick,
      createCustomAppointment,
      handleUpdateEvents,
    })

    const data = useMemo(() => extend([], events, null, true), [events])

    const resourceHeaderTemplate = useCallback(
      (props: ObjectType) => {
        const selectedResource = props.resourceData.resourceId
        return (
          resources.find(({ resourceId }) => resourceId === selectedResource)?.resourceTemplate ||
          ''
        )
      },
      [resources],
    )

    const handleZoomOut = useCallback(() => {
      setInterval((level) => (level < 15 ? level + 1 : level))
    }, [])

    const handleZoomIn = useCallback(() => {
      setInterval((level) => (level > 1 ? level - 1 : level))
    }, [])

    const indentTemplate = useCallback(() => {
      return <ZoomControls onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} />
    }, [handleZoomIn, handleZoomOut])

    const eventRendered = useCallback((props: any) => {
      props.element.style.color = InfoService.getScheduleColor(props.data.status.code)?.color
      props.element.style.background = InfoService.getScheduleColor(
        props.data.status.code,
      )?.backgroundColor
      props.element.style.border = `1px solid #dee2e6`
    }, [])

    const eventTemplate = useCallback(
      (props: any) => {
        return <CalendarEvent event={props} />
      },
      [events],
    )

    // const eventTooltip = useCallback((props: any) => {
    //   return <EventTooltip event={props} />
    // }, [])

    const handleEventClick = useCallback((props: any) => {
      props.originalEvent.preventDefault()
      props.originalEvent.stopPropagation()

      setEditedPatient(props.event)
    }, [])

    const handleChange = useCallback(
      async (props: any) => {
        if (props.requestType !== 'eventChange' || isInPast) {
          return
        }

        setIsLoading(true)

        handleScheduleChange(props.data)
          .then(() => {
            setIsLoading(false)
            handleRemoveSelectedCells()
          })
          .catch(() => {
            setIsLoading(false)
            handleRemoveSelectedCells()
          })
      },
      [isInPast],
    )

    const handleToogleSendSmsConfirmVisible = useCallback(
      (event: boolean) => () => {
        setIsSendSmsConfirmVisible(event)
      },
      [],
    )

    const handleSendNotifications = useCallback(async () => {
      await sendInfoPatientsNotification()
      setIsSendSmsConfirmVisible(false)
    }, [])

    const handleToggleSendSmsConfirmVisible = useCallback(
      (event: boolean) => () => {
        setIsSendSmsConfirmVisible(event)
      },
      [],
    )

    const handleToggleSendBusyNotification = useCallback(
      (event: boolean) => () => {
        setShowBusyNotification(event)
      },
      [],
    )

    const handleRenderCell = useCallback(
      (args: ObjectType) => {
        if (args.elementType !== 'workCells') {
          return
        }

        const resource = resources[args.groupIndex]
        const room = rooms.find((room: ObjectType) => {
          return resource.resourceId === `${room.label}/${room.value}`
        })

        if (!room || !room.slots) {
          return
        }

        const cellStartTime = new Date(args.date)
        const cellEndTime = new Date(cellStartTime)

        const isAvailable = room.slots.some((slot: ObjectType) => {
          const slotStart = new Date(args.date)
          slotStart.setHours(
            parseInt(slot.start_time.split(':')[0], 10),
            parseInt(slot.start_time.split(':')[1], 10),
            0,
            0,
          )

          const slotEnd = new Date(args.date)
          slotEnd.setHours(
            parseInt(slot.end_time.split(':')[0], 10),
            parseInt(slot.end_time.split(':')[1], 10),
            0,
            0,
          )

          return cellStartTime >= slotStart && cellEndTime < slotEnd
        })

        if (!isAvailable) {
          args.element.classList.add('e-disabled-cell')
        } else if (args.elementType === 'monthCells') {
          const cellDate = args.date
          if (cellDate && cellDate.getHours() >= 18) {
            args.element.style.backgroundColor = '#FFFFFF'
          }
        }
      },
      [resources, rooms],
    )

    const majorSlotTemplate = useCallback((props: any) => {
      return <div className='e-time-slot-wrap'>{dayjs(props.date).format('h:mm A')}</div>
    }, [])

    const handlePopupOpen = useCallback((props: any) => {
      if (props.type === 'Editor') {
        props.cancel = true
      }
    }, [])

    const handleRemoveSelectedCells = useCallback(() => {
      document.querySelectorAll('.e-selected-cell')?.forEach((el) => {
        el.classList.remove('e-selected-cell')
      })
    }, [])

    useEffect(() => {
      if (scheduleObj.current) {
        const currentTimeElement = document?.querySelector('.e-current-time')

        if (currentTimeElement) {
          currentTimeElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      }

      const handleClickOutside = (event: MouseEvent) => {
        if (
          scheduleContainerRef.current &&
          !scheduleContainerRef.current.contains(event.target as Node)
        ) {
          handleRemoveSelectedCells()
        }
      }

      document.addEventListener('click', handleClickOutside)

      return () => {
        document.removeEventListener('click', handleClickOutside)
      }
    }, [scheduleObj.current, handleRemoveSelectedCells])

    return (
      <div className='schedule-control-section' ref={scheduleContainerRef}>
        <div className='col-lg-12 control-section'>
          <div className={`control-wrapper ${isLoading || !timeZone ? 'loading' : ''}`}>
            {resources && timeZone && (
              <ScheduleComponent
                ref={scheduleObj}
                cssClass='room-schedule'
                width='100%'
                height='calc(100vh - 300px)'
                timezone={timeZone}
                timeScale={{ interval, slotCount: 1, majorSlotTemplate }}
                selectedDate={dayjs(day).tz(timeZone).toDate()}
                startHour={dayInterval.min as string}
                endHour={dayInterval.max as string}
                currentView='Day'
                headerIndentTemplate={indentTemplate}
                resourceHeaderTemplate={resourceHeaderTemplate}
                eventRendered={eventRendered}
                showQuickInfo={false}
                eventClick={handleEventClick}
                actionBegin={handleChange}
                showHeaderBar={false}
                select={handleSelect}
                renderCell={handleRenderCell}
                popupOpen={handlePopupOpen}
                allowDragAndDrop={!isInPast}
                eventSettings={{
                  dataSource: data,
                  enableTooltip: false,
                  enableIndicator: !isInPast,
                  // tooltipTemplate: eventTooltip,
                  fields: {
                    startTime: { title: 'From', name: 'start_time' },
                    endTime: { title: 'To', name: 'end_time' },
                  },
                }}
                group={{ resources: ['Rooms'], enableCompactView: false }}>
                <ResourcesDirective>
                  <ResourceDirective
                    field='room_id'
                    title='Rooms'
                    name='Rooms'
                    dataSource={resources}
                    idField='resourceId'
                  />
                </ResourcesDirective>
                <ViewsDirective>
                  <ViewDirective option='Day' eventTemplate={eventTemplate} />
                </ViewsDirective>
                <Inject services={[Day, Resize, DragAndDrop]} />
              </ScheduleComponent>
            )}

            <Show
              when={
                !DateService.isPast(new Date(`${formattedDate} 23:59`), timeZone) &&
                eventsToSendNotification.length > 0
              }>
              <div className='action'>
                <Button
                  modifier={BUTTON_MODIFIER.QUATERNARY}
                  onClick={handleToogleSendSmsConfirmVisible(true)}
                  loading={isSendingNotifications}>
                  <span className='center' style={{ gap: 8 }}>
                    {!isSendingNotifications && (
                      <img
                        className='actionIcon'
                        src={sendMessageIcon}
                        alt={ALT_CONSTANTS.ACTION_ICON}
                      />
                    )}
                    <span>Notify patients</span>
                  </span>
                </Button>
              </div>
            </Show>
          </div>

          <div className='control-spinner'>
            <Spinner isLoading={isLoading} size='large' />
          </div>
        </div>

        <Warning
          open={Boolean(awaitConfirmationEvent)}
          title={INFO_CONSTANTS.ARE_YOU_SURE}
          warning={INFO_CONSTANTS.MULTIPLE_PATIENTS_TITLE}
          okButtonText={INFO_CONSTANTS.YES}
          handleClose={() => setAwaitConfirmationEvent(null)}
          handleConfirm={proceedToUpdateEvents}
          hasCancel
        />

        <Warning
          open={showBusyNotification}
          title={INFO_CONSTANTS.INFO}
          warning={INFO_CONSTANTS.BUSY_ROOM_WARNING}
          handleClose={handleToggleSendBusyNotification(false)}
          okButtonText={BUTTON_CONSTANTS.CLOSE}
          handleConfirm={handleToggleSendBusyNotification(false)}
        />

        <SendSMSConfirmation
          open={isSendSmsConfirmVisible}
          handleConfirm={handleSendNotifications}
          handleClose={handleToggleSendSmsConfirmVisible(false)}
        />
      </div>
    )
  },
)
