import React, { createContext, useContext, useEffect, useState, useRef } from 'react'
import type { FC, ReactNode } from 'react'
import isEqual from 'lodash/isEqual'

import Pusher from 'pusher-js'
import type { Channel } from 'pusher-js'

import { PUSHER_CLUSTERS, PUSHER_KEYS } from 'common/constants/siteSelectorConstants'

type ContentProps = {
  channels: Map<string, Channel>
  pusher: Pusher | null
  bookingIds: string[]
  setBookingIds: React.Dispatch<React.SetStateAction<string[]>>
}

type PusherProviderProps = {
  children: ReactNode
  selectedSite: string
}

const MultipleChannelsContext = createContext<ContentProps>({
  channels: new Map(),
  pusher: null,
  bookingIds: [],
  setBookingIds: () => {},
})

export const debugPusher = false

export const MultiplePusherProvider: FC<PusherProviderProps> = ({ children, selectedSite }) => {
  const [channels, setChannels] = useState<Map<string, Channel>>(new Map())
  const [pusher, setPusher] = useState<Pusher | null>(null)

  const [bookingIds, setBookingIds] = useState<string[]>([])

  const prevBookingIds = useRef<string[]>([])

  useEffect(() => {
    if (!selectedSite || !bookingIds.length) {
      return
    }

    if (isEqual(bookingIds, prevBookingIds.current)) {
      return
    }

    prevBookingIds.current = bookingIds

    const pusherInstance = new Pusher(PUSHER_KEYS[selectedSite], {
      cluster: PUSHER_CLUSTERS[selectedSite],
    })

    pusherInstance.connection.bind('state_change', (states: any) => {
      if (debugPusher) console.log('Pusher connection state changed:', states)
    })

    pusherInstance.connection.bind('connected', () => {
      if (debugPusher) console.log('Pusher connected successfully!')
    })

    pusherInstance.connection.bind('error', (err: any) => {
      if (debugPusher) console.error('Pusher connection error:', err)
    })

    setPusher(pusherInstance)

    const newChannels = new Map<string, Channel>()
    bookingIds.forEach((bookingId) => {
      if (bookingId) {
        if (debugPusher) console.log(`Subscribing to channel: ${bookingId}`)
        const channel = pusherInstance.subscribe(String(bookingId))

        // Debug subscription state
        channel.bind('pusher:subscription_succeeded', () => {
          if (debugPusher) console.log(`Successfully subscribed to channel: ${bookingId}`)
        })

        channel.bind('pusher:subscription_error', (error: any) => {
          if (debugPusher) console.error(`Error subscribing to channel ${bookingId}:`, error)
        })

        newChannels.set(String(bookingId), channel)
      }
    })

    setChannels(newChannels)

    return () => {
      bookingIds.forEach((bookingId) => {
        if (bookingId) {
          pusherInstance.unsubscribe(String(bookingId))
        }
      })

      pusherInstance.disconnect()
    }
  }, [selectedSite, JSON.stringify(bookingIds)])

  const value = { channels, pusher, bookingIds, setBookingIds }

  return (
    <MultipleChannelsContext.Provider value={value}>{children}</MultipleChannelsContext.Provider>
  )
}

export const useMultipleChannels = (): ContentProps => {
  const context = useContext(MultipleChannelsContext)
  if (!context) {
    throw new Error('useMultipleChannels must be used within a MultiplePusherProvider')
  }

  return context
}
