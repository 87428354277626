import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

export const generateDates = (start: string | Dayjs | Date, timeZone?: string): Dayjs[] => {
  if (!dayjs(start).isValid()) return []
  const startDate = timeZone
    ? dayjs(start).tz(timeZone, true).startOf('week')
    : dayjs(start).startOf('week')
  const dates = []

  for (let i = 0; i < 7; i++) {
    dates.push(startDate.clone().add(i, 'days'))
  }

  return dates
}
