import {
  useCheckInPatientMutation,
  useCreateScheduleAppointmentMutation,
  useDeleteScheduleAppointmentMutation,
  useNotifyPatientMutation,
  useUpdateScheduleAppointmentMutation,
  useUpdateRoomStatusMutation,
} from 'features/Schedule/api/schedule.api'

import type { ScheduleAppointmentDto } from 'features/Schedule/api/schedule.types'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useNotification } from 'app/providers/notification/NotificationProvider'
import { ALERT_CONSTANTS } from 'common/constants/alertConstants'
import { useAppDispatch } from 'common/hooks/redux'
import { UPDATE_INFO_PATIENTS_SELECTION } from 'features/Home/Book/state/slice/bookSlice'
import { type IPatientRoom } from 'features/Home/interfaces/IInfoPatient'

export const useScheduleActions = () => {
  const { siteId } = useParams()
  const dispatch = useAppDispatch()
  const { setNotification } = useNotification()
  const [createScheduleAppointment, { isLoading: isCreating }] =
    useCreateScheduleAppointmentMutation()
  const [updateScheduleAppointment, { isLoading: isUpdating }] =
    useUpdateScheduleAppointmentMutation()

  const [notifySchedulePatient, { isLoading: isNotifying }] = useNotifyPatientMutation()

  const [deleteScheduleAppointment, { isLoading: isDeleting }] =
    useDeleteScheduleAppointmentMutation()

  const [checkInSchedulePatient, { isLoading: isCheckingIn }] = useCheckInPatientMutation()
  const [updateScheduleRoomStatus, { isLoading: isUpdatingRoomStatus }] =
    useUpdateRoomStatusMutation()

  const handleError = (error: any) => {
    const errorMessage = error?.data?.message || 'An error occurred'
    setNotification({
      title: 'Error',
      description: errorMessage,
      type: ALERT_CONSTANTS.ERROR,
    })
  }

  const createAppointment = async (data: ScheduleAppointmentDto, date: string) => {
    try {
      return await createScheduleAppointment({
        siteId,
        date,
        data,
      }).unwrap()
    } catch (error) {
      handleError(error)
      throw error
    }
  }

  const updateAppointment = async (
    data: ScheduleAppointmentDto,
    date: string,
    appointmentId: number,
  ) => {
    try {
      return await updateScheduleAppointment({
        siteId,
        date,
        appointmentId,
        data,
      }).unwrap()
    } catch (error) {
      handleError(error)
      throw error
    }
  }

  const notifyPatient = async (appointmentId: number) => {
    try {
      return await notifySchedulePatient({
        siteId,
        appointmentId,
      }).unwrap()
    } catch (error) {
      handleError(error)
      throw error
    }
  }

  const deleteAppointment = async (date: string, appointmentId: number) => {
    try {
      return await deleteScheduleAppointment({
        siteId,
        date,
        appointmentId,
      }).unwrap()
    } catch (error) {
      handleError(error)
      throw error
    }
  }

  const checkInPatient = async (
    appointmentId: number,
    access_code: number | string,
    date: string,
  ) => {
    try {
      return await checkInSchedulePatient({
        siteId,
        date,
        appointmentId,
        access_code,
      }).unwrap()
    } catch (error) {
      handleError(error)
      throw error
    }
  }

  const updateRoomStatus = async (
    room: IPatientRoom,
    data: { status_type_code: number; lease_id: string },
    date: string,
  ) => {
    try {
      const roomId = room.id
      const response = await updateScheduleRoomStatus({
        siteId,
        date,
        roomId,
        data,
      }).unwrap()

      dispatch(
        UPDATE_INFO_PATIENTS_SELECTION({
          room,
          patients: response?.patients || [],
          isPatientSelectionVisible: Boolean(response?.patients?.length),
        }),
      )
      return response
    } catch (error) {
      handleError(error)
      throw error
    }
  }

  const isLoading = useMemo(() => {
    return isCreating || isUpdating
  }, [isCreating, isUpdating])

  return {
    createAppointment,
    updateAppointment,
    notifyPatient,
    deleteAppointment,
    checkInPatient,
    updateRoomStatus,
    isLoading,
    isCreating,
    isUpdating,
    isNotifying,
    isDeleting,
    isCheckingIn,
    isUpdatingRoomStatus,
  }
}
