import 'styles/index.scss'
import type { FC } from 'react'
import { useEffect, useMemo } from 'react'
import { Navigate, useParams } from 'react-router-dom'

import Header from 'layout/Header/Header'
import { Sidebar } from 'layout/Sidebar/Sidebar'
import { PATH_SEGMENT } from 'routes/pathSegments'
import { generateRouter } from 'routes/routes.utils'

import { useScreenWidth } from 'app/hooks/useScreenWidth'
import { useAuth, useNotification } from 'app/providers'

import { CartWidget } from 'common/components/CartWidget/CartWidget'
import { Show } from 'common/components/Show/Show'

import { Admin } from 'features/Admin'
import { Cart, useCartManager } from 'features/Cart'
import { Faq } from 'features/Faq'
import { Home } from 'features/Home'
import { Patients } from 'features/Patients'
import { Profile } from 'features/Profile/Profile'
import { Provider } from 'features/Provider/pages/Provider'
import { Staff } from 'features/Staff'
import { Support } from 'features/Support/pages/Support'
import GenericModal from 'common/components/GenericModal'
import { useShopCartManager } from 'features/Option/hooks/useShopCartManager'
import { Spinner } from 'common/components/Spinner'
import { Schedule } from 'features/Schedule'

export const Main: FC = () => {
  const { siteId } = useParams()
  const { isMobile } = useScreenWidth()
  const { notification } = useNotification()
  const { fetchCartsData } = useCartManager()
  const { fetchShopCartData } = useShopCartManager()
  const { toggleIsSidebarVisible, user, isUnconfirmedDoctor, isSidebarVisible } = useAuth()

  const routesConfig = useMemo(
    () => ({
      [PATH_SEGMENT.FAQ]: <Faq />,
      [PATH_SEGMENT.STAFF]: <Staff />,
      [`${PATH_SEGMENT.ADMIN}/*`]: <Admin />,
      [PATH_SEGMENT.PATIENTS]: <Patients />,
      [`${PATH_SEGMENT.HOME}/*`]: <Home />,
      [`${PATH_SEGMENT.PROVIDER}/*`]: <Provider />,
      ...(!isUnconfirmedDoctor && {
        [`${PATH_SEGMENT.PROFILE}/*`]: <Profile />,
      }),
      [`${PATH_SEGMENT.SCHEDULE}/*`]: <Schedule />,
      [`${PATH_SEGMENT.SUPPORT}/*`]: <Support />,
      [PATH_SEGMENT.OTHER]: <Navigate to={PATH_SEGMENT.HOME} replace />,
    }),
    [isUnconfirmedDoctor],
  )

  useEffect(() => {
    if (!siteId) {
      return
    }

    fetchCartsData()
    fetchShopCartData()
  }, [siteId])

  return (
    <Show
      when={!!user}
      fallback={
        <div className='whole-page-wrapper'>
          <Spinner size='large' isLoading={true} />
        </div>
      }>
      <>
        <Header />
        <Show when={!isMobile}>
          <Sidebar />
        </Show>
        <Show when={isMobile && isSidebarVisible}>
          <Sidebar
            isSidebarVisible={isSidebarVisible}
            toggleIsSidebarVisible={toggleIsSidebarVisible}
          />
        </Show>

        <div className='application-layout__content'>
          <div
            className='application-layout__content-container'
            onClick={() => isMobile && isSidebarVisible && toggleIsSidebarVisible()}>
            {notification}
            {generateRouter(routesConfig)}

            <Cart />
            <CartWidget />
            <GenericModal />
          </div>
        </div>
      </>
    </Show>
  )
}
